import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_alltomtraning/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "allt-om-träning"
    }}>{`Allt Om Träning`}</h1>
    <p>{`Välkommen till Allt Om Träning! Tanken med denna sida är att vi vill hjälpa dig komma igång med träning, med fokus på att träna hemma. Man behöver inte ha ett dyrt gymkort och släpa sig till gymmet tidigt på morgonen, på lunchen eller sent på kvällen. Med lite enkla redskap kan du snabbt och smidigt få till ett hemmagym och tröskeln blir genast mycket lägre för dig när du vill köra ett pass.`}</p>
    <p>{`Här på Allt Om Träning så har vi delat upp träning i olika kategorier beroende på vad du vill uppnå med din träning och vad du har för redskap hemma. Vissa har något enstaka elastiskt band och vill fokusera på löpning och stretching, medans andra vill bygga muskler och har tillgång till ett helt garage med redskap.`}</p>
    <p>{`Vet du redan vad du är ute efter? Klicka in på rätt kategori ovan så hittar du övningar, tips och produkter som du kan behöva för att kopletera ditt hemmagym. Längre fram kommer vi också blogga inom de olika kategorierna och ta in expeter som hjälper just dig med dina övningar och hjälper dig att komma igång!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      